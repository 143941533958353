import React from 'react';
import 'antd/dist/antd.css';
import { PHOTOS, TAG_STYLE } from '../../Constants';
import Gallery from 'react-grid-gallery';
import '../../css/photoscontainer.css'

export default function PhotosContainer() {
    return(
        <div style={{
            display: "block",
            padding:'30px',
            minHeight: "1px",
            width: "100%",
            overflow: "auto"}}>
        {/*<div style={{padding:'1.5%'}}>*/}
            <Gallery images={PHOTOS}
                     enableImageSelection={false} rowHeight={'230'}
                     tagStyle={TAG_STYLE}
                     margin={4}
            />
            <br/>
            <br/>
            <br/>
        </div>
    );
}




