import React from "react";
import { Timeline } from "antd";

export default function Tl() {
    return(
        <div style={{paddingTop:"30px"}}>
            <Timeline mode="alternate">
                <Timeline.Item color="#63a3e3"><strong>2016/08 </strong>
                    WPI
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2019/07 - 2019/12 </strong>
                    Internship at China Telecom Americas
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong> 2020/01 - 2020/03 </strong>
                    Internship at Shape Security(Part of F5)
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2020/05 </strong>
                    Graduated from WPI with High Distinct
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2020/10 - 2021/05 </strong>
                    Technical Service Engineer at Tellusicom Inc
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2021/09 - 2022/12 </strong>
                    Columbia University
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2022/07 - 2023/01 </strong>
                    Worked full-time at Ragon Institute of MGH, MIT and Harvard
                </Timeline.Item>
                <Timeline.Item color="#63a3e3"><strong>2023/04 - Present </strong>
                    Worked full-time at AutoParts
                </Timeline.Item>
                <Timeline.Item color="gray" >
                    <strong>Continued...</strong>
                </Timeline.Item>
            </Timeline>
        </div>
    );
}