import React, { Component } from 'react';
import { Row, Col } from 'antd';
import '../../css/homecontainer.css';
import { init } from 'ityped';

export default class HomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount(){
        const myElement = document.querySelector('#typed')
        init(myElement, {
            disableBackTyping: false,
            typeSpeed:  70,
            backSpeed:  40,
            showCursor: true,
            strings: ['Welcome to my personal website!', 'I am here to create and design your next experience.' ] })
    }

    render() {
        return (
            <div >
                <div className="home-container">
                    <img src='/images/aloha3.jpeg' alt='bg pic' style={{width:'100%',height:'100%', opacity:'0.8'}}/>
                </div>
                <Row style={{  padding:'40px', height:'100%'}}>
                    <Col span={24} >
                        <span id="typed" style={{fontFamily:'Major Mono Display'}}/>
                    </Col>
                </Row>
            </div>
        )
    }
}