//ProjectContainer.js
export const PROJECT_DATA = [
    {
        name: 'LeetGroup',
        imgurl: '/images/leetGroup.png',
        id: 'leetgroup',
        detail: 'LeetGroup is a dynamic online platform designed to facilitate coding practice, ' +
            'particularly for tackling challenging LeetCode questions. ' +
            'The mission of LeetGroup is to empower users in optimizing their learning journeys ' +
            'while fostering a strong sense of camaraderie among friends and fellow learners as they advance together.',
        link: 'https://www.youtube.com/watch?v=VLS8GaZrAr0'
    },
    {
        name: 'Cat Breeder Learning App',
        imgurl: '/images/catWebsite.png',
        id: 'catwebsite',
        detail: 'This project designed and implemented a web application that facilitates users in learning about various cat breeds. ' +
            'Users can assess their knowledge through interactive quizzes and receive valuable feedback upon quiz completion.',
        link: 'https://www.youtube.com/watch?v=2rZO47zdLm4&feature=youtu.be'
    },
    {
        name: 'Sanrio Wikipedia',
        imgurl: '/images/sanrio.png',
        id: 'sanrio',
        detail: 'This project built a Sanrio Wikipedia.',
        link: 'https://www.youtube.com/watch?v=SP82KYnhCW4&t=44s'
    },
    {
        name: 'Photo Album',
        imgurl: '/images/photo.png',
        id: 'photo',
        detail: 'This project designed and implemented a web application that facilitates users in learning about various cat breeds. ' +
            'Users can assess their knowledge through interactive quizzes and receive valuable feedback upon quiz completion.',
        link: 'https://www.youtube.com/watch?v=2rZO47zdLm4&feature=youtu.be'
    },
    {
        name: 'Audio-based Social Media App',
        imgurl: '/images/audioBasedSocialApp.png',
        id: 'socialmediaapp',
        detail: 'This project designed a voice-driven interface enabling users to record audio questions, which are then answered by experts in the relevant field.' +
            'The social media app is user-friendly, seamlessly integrating APIs and triggering Lambda functions for efficient data retrieval from the backend. ' +
            'It utilized various AWS services to ensure the application\'s reliability and performance. It also utilized a pre-train BERT model for generating question embeddings and a Blazing Text Model' +
            'for word2vec and text classification via SageMaker.'
    },
    {
        name: 'Grade Calculator',
        imgurl: '/images/gradeCalculator.png',
        id: 'gradecalculator',
        detail: 'This project developed a grade calculator to calculate grades for WPI students.',
        link:'http://a2-dababa.herokuapp.com/'
    },
    {
        name: 'Hospital Kiosk',
        imgurl: '/images/softeng.jpeg',
        id: 'softeng',
        detail: 'The hospitality kiosk is engineered for users to enhance their experience in Brigham and Women’s Hospital by providing path finding, service requests, e-mail/SMS messages and other functions.',
        link:''
    },
    {
        name: 'Event Management Web App',
        imgurl: '/images/eventManger.png',
        id: 'eventManage',
        detail: 'This web application allows user to add, store, delete and update event records which store in the MongoDB. User could only update one record at a time and the edit button will be disabled if more than one checkboxes are clicked. In addition, user could delete one or more records based on the user\'s choice.',
        link:'https://a3-ruyuewang.herokuapp.com'
    },
    {
        name: 'Sticky Post',
        imgurl: '/images/stickpost.png',
        id: 'stickypost',
        detail: 'This project designed and implemented a social media application called Sticky Post, ' +
            'which allows users to leave messages and share feelings with friends, families or colleagues.',
        link:''
    },
    {
        name: 'Smart Fire Trucks',
        imgurl: '/images/smartfiretruck.png',
        id: 'smartfiretruck',
        detail: 'Fire engines in China are facing challenges and limitations when driving to and while attempting\n' +
            'to extinguish fires. These challenges and limitations are a direct result of the progressive urban\n' +
            'development of cities and the outdated technology and framework of the fire engines currently in\n' +
            'use. This proposal is an analysis of current fire engines technology and it explores other options\n' +
            'to adapt to the changing streets of the busy cities in China. With guidance from Tsinghua-WPI\n' +
            'Center for Global Public Safety, the research focuses on smart technologies and whether it can\n' +
            'benefit fire engines in China by making them more adaptable to the changing urban landscape.',
        link:'https://digitalcommons.wpi.edu/cgi/viewcontent.cgi?article=6124&context=iqp-all'
    },
    {
        name: 'House Price Prediction',
        imgurl: '/images/houseprice.png',
        id: 'houseprice',
        detail: 'With millions of houses in the market, it is difficult to estimate the valuation of a house. ' +
            'This project created a model that will predict the valuation of a house in Ames, Iowa. ' +
            'The data was obtained from Kaggle competition' +
            'The model made use of a dataset that contains 79 variables which cover almost every aspect of a house. '
    },
    {
        name: 'Credit Card Fraud Detector',
        imgurl: '/images/creditcard.png',
        id: 'creditcard',
        detail: 'Applied various machine learning models, including random forest, ' +
            'XGBoost, and decision tree in R to predict fraudulent credit card transactions from the dataset.'
    },
    {
        name: 'User Event Visualizer',
        imgurl: '/images/vizmonkey.png',
        id: 'vizmonkey2',
        detail: 'A replacement for the current tool used by Security Intelligence Center (SIC) to manually interpret user interaction events recorded in signal data. The current tool is very limited and requires instruction to interpret its output. It lacks the ability to visualise much of the data we have and lacks features that would be helpful for the expanded scope of its use since its inception. This redesign will allow us to perform manual analysis more quickly and easily, share visualisations without the need for an accompanying interpretation, and provide our customers with much more professional manual analysis reports. \n',
        link: "https://web.cs.wpi.edu/~claypool/mqp/sv/2020/shape/report-final.pdf"
    },
    {
        name: 'Movie Rating Web App',
        imgurl: '/images/movie.png',
        id: 'movie',
        detail: 'This project implemented a simple movie classification web app that allows users ' +
            'to see showing, top rated and coming soon movies by using movie data from The Movie Database (TMDb).'
    }
]

//PhotosContainer.js
export const PHOTOS =
    [
        {
            src: "/images/wpi1.jpeg",
            thumbnail: "/images/wpi1.jpeg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            tags: [{value: "WPI", title: "WPI"}],
            caption: "WPI | Worcester, MA"
        },
        {
            src: "/images/aloha1.jpg",
            thumbnail: "/images/aloha1.jpg",
            thumbnailWidth:320,
            thumbnailHeight: 174,
            tags: [{value: "Hawaii", title: "Hawaii"}],
            caption: "Honolulu, Hawaii"
        },
        {
        src: "/images/aloha2.jpg",
        thumbnail: "/images/aloha2.jpg",
        thumbnailWidth:320,
        thumbnailHeight: 174,
        tags: [{value: "Hawaii", title: "Hawaii"}],
        caption: "Honolulu, Hawaii"
        },
        {
            src: "/images/japan1.jpeg",
            thumbnail: "/images/japan1.jpeg",
            thumbnailWidth: 320,
            thumbnailHeight: 172,
            tags: [{value: "Japan", title: "Japan"}],
            caption: "Shodoshima, Japan"
        },
        {
            src: "/images/japan2.jpg",
            thumbnail: "/images/japan2.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 172,
            tags: [{value: "Japan", title: "Japan"}],
            caption: "Nagoya, Japan"
        },
        {
            src: "/images/japan3.jpg",
            thumbnail: "/images/japan3.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 172,
            tags: [{value: "Japan", title: "Japan"}],
            caption: "Toyama, Japan"
        },
        {
            src: "/images/japan4.jpg",
            thumbnail: "/images/japan4.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 172,
            tags: [{value: "Japan", title: "Japan"}],
            caption: "Shinhotaka | Takayama, Japan"
        },
        {
            src: "/images/glenstone.jpeg",
            thumbnail: "/images/glenstone.jpeg",
            thumbnailWidth: 320,
            thumbnailHeight: 172,
            tags: [{value: "Maryland", title: "Maryland"}],
            caption: "Glenstone | Maryland"
        },
        {
            src: "/images/wpi2.jpeg",
            thumbnail: "/images/wpi2.jpeg",
            thumbnailWidth: 270,
            thumbnailHeight: 320,
            tags: [{value: "WPI", title: "WPI"}],
            caption: "WPI | Worcester, MA"
        }
    ]

export const TAG_STYLE = {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    fontWeight: "600",
    lineHeight: "1",
    color: "white",
    background: "rgba(0,0,0,0.65)",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25em"
}