import React, {Component} from 'react';
import {Col, Row, Divider} from "antd";
import Tl from './Timeline';
import '../../css/aboutcontainer.css';

export default class AboutContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div style={{padding:'20px',}}>
                <Row>
                    <Col span={6} style={{background:'white', textAlign:'center', paddingTop:'60px'}}>
                        <img src='/images/selfpic.jpg' alt='self pic' style={{width:'180px',height:'180px'}}/>
                    </Col>
                    <Col span={18} style={{background:"white", padding:'0px'}}>
                        <Divider orientation="left" style={{fontFamily:'Major Mono Display', fontSize:'23px'}}>WHO AM I ?</Divider>
                        <p style={{ fontSize:'17px'}}>My name is Ruyue Wang and I earned a master degree in Computer Science from Columbia University in the city of New York.
                            <br/>
                            <br/>
                            My enduring passion lies in computer technology and its practical applications for solving real-world challenges.
                            I thrive on overcoming obstacles and eagerly anticipate opportunities that allow me to take on greater responsibilities.
                        </p>
                        <br/>
                        <Divider orientation="left" style={{fontFamily:'Major Mono Display', fontSize:'23px'}}>CONTACT ME</Divider>
                        <p style={{fontSize:'17px'}}>E-mail: <a href = "mailto: rw2905@columbia.edu">rw2905@columbia.edu</a></p>
                    </Col>
                </Row>
                <br/>
                <Tl/>
            </div>
        )
    }
}