import {Card, Col, Divider, Image, Row} from "antd";
import React from "react";

export default function Handcrafts() {
    return(
        <>
            <Divider style={{fontFamily:'Major Mono Display', fontSize:'20px'}}>HANDCRAFTS</Divider>
            <h2 style={{textAlign:'center', padding:"15px", fontFamily:'Dancing Script'}}>
                The Art of hand dyed flowers: making flowers by using millinery tools.
            </h2>
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={8}>
                        <Card
                            title="Roses"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    alt="Art Flower"
                                    src="/images/artflower1.jpg"
                                />
                            }
                        >
                            Art flower of roses
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="Cherry Blossom"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    alt="Art Flower"
                                    src="/images/artflower2.jpg"
                                />
                            }
                        >
                            Art flower of cherry blossom
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="Tools"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    alt="Art Flower"
                                    src="/images/artflower3.jpg"
                                />
                            }
                        >
                            Picture of millinery tools to made art flower
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}