import React from 'react';
import '../css/otherscontainer.css';
import Paintings from "./Paintings";
import Handcrafts from "./Handcrafts";

export default function OthersContainer() {
    return(
        <div style={{padding:'30px'}}>
            <Handcrafts/>
            <Paintings/>
            <br/>
            <br/>
        </div>
    );
}


