import React, {Component} from 'react';
import ProjectItem from './ProjectItem';
import {PROJECT_DATA as PROJ_DATA} from '../../Constants';

export default class ProjectsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: PROJ_DATA
        }
    }

    render() {
        return (
            <div style={{padding:'30px'}}>
                <div style={{ display: 'flex',flexWrap:'wrap', justifyContent:'center',paddingBottom:'25px'}}>
                    {this.state.projects.map(item =>
                        <ProjectItem {...item} key={item.id} item={item}/>)
                    }
                </div>
            </div>
        )
    }
}