
import React, { Component } from 'react';
import './App.css';
//import router component
import { HashRouter, Route, Link, Redirect } from 'react-router-dom'
//import route components
import HomeContainer from './components/home/HomeContainer';
import AboutContainer from './components/about/AboutContainer';
import ProjectsContainer from './components/projects/ProjectsContainer';
import PhotosContainer from './components/photos/PhotosContainer';
import OthersContainer from './components/OthersContainer';
//import UI from antd
import { Layout, Menu } from 'antd'
import { HeartIcon } from "./components/icon";
const { Header, Content, Footer } = Layout;


class App extends Component{
  render(){
    console.log("test"+ window.location.hash.split('/')[1])
    return (
        <HashRouter>
          <Layout className="layout" style={{height:'100%'}}>

            {/*Header*/}
            <Header className="header">
              {/*<div className="logo"/>*/}
              <Menu
                  mode="horizontal"
                  defaultSelectedKeys= {typeof(window.location.hash.split('/')[1]) == "undefined"?'home': window.location.hash.split('/')[1]}
                  style={{ lineHeight: '64px' , backgroundColor:'whitesmoke'}}
              >
                <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
                <Menu.Item key="about"><Link to="/about">About</Link></Menu.Item>
                <Menu.Item key="projects"><Link to="/projects">Projects</Link></Menu.Item>
                <Menu.Item key="photos"><Link to="/photos">Photos</Link></Menu.Item>
                <Menu.Item key="others"><Link to="/others">Others</Link></Menu.Item>
              </Menu>
            </Header>

            {/*Content*/}
            <Content style={{ padding: '0', background: '#fff', flex:'1'}}>
                <div className="site-layout-background" style={{ padding: 0, minHeight: 380 }}>
                    <Route exact path="/"><Redirect to="/home" /></Route>
                    <Route path="/home" component={HomeContainer}/>
                    <Route path="/about" component={AboutContainer}/>
                    <Route path="/projects" component={ProjectsContainer}/>
                    <Route path="/photos" component={PhotosContainer}/>
                    <Route path="/others" component={OthersContainer}/>
                </div>
            </Content>

            {/*Footer*/}
            <Footer className='footer'>Ruyue's Personal Website ©2020 Created by Ruyue <HeartIcon/></Footer>
          </Layout>
        </HashRouter>
    );
  }
}

export default App;
