import React, {Component} from 'react';
import '../../css/projectitem.css';
import {Modal} from "antd";

export default class ProjectItem extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false};
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        let link;
        if (this.props.link) {
            link = <a href={this.props.link} target="_blank">Visit Project</a>;
        }
        return(
            <>
                <div className="project-box" onClick={this.showModal}>
                    <img src= {this.props.imgurl} className="img" alt=""/>
                    <h4>{this.props.name}</h4>
                </div>
                <Modal
                    title={this.props.name}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <img src= {this.props.imgurl} className="proj-img" alt=""/>
                    <br/>
                    <p>{this.props.detail}</p>
                    {link}
                </Modal>
            </>
        )
    }

}

