import {Card, Col, Divider, Image, Row} from "antd";
import React from "react";

export default function Paintings() {
    return(
        <>
            <Divider style={{fontFamily:'Major Mono Display', fontSize:'20px', paddingTop:'30px'}}>PAINTINGS</Divider>
            <h2 style={{textAlign:'center', padding:"15px", fontFamily:'Dancing Script'}}>
                Random paintings
            </h2>
            <div className="site-card-wrapper">
                <Row gutter={16} style={{padding:'20px'}}>
                    <Col span={8}>
                        <Card
                            title="Metro Girl"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting1.jpeg"
                                />
                            }
                        >
                            Metro Girl Series - 1
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="パンになりかけのひと"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting3.png"
                                />
                            }
                        >
                           Man who is becoming bread
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="On the Way to Work"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting8.jpeg"
                                />
                            }
                        >
                            On the way to work
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} style={{padding:'20px'}}>
                    <Col span={8}>
                        <Card
                            title="Metro Girl "
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting6.jpeg"
                                />
                            }
                        >
                            Metro Girl Series - 2
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="Lonely Girl"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting7.jpeg"
                                />
                            }
                        >
                            Lonely girl
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="目"
                            headStyle={{backgroundColor:'#ebf4fc'}}
                            bodyStyle={{backgroundColor:'#ebf4fc'}}
                            hoverable={true}
                            cover={
                                <Image
                                    src="/images/painting5.png"
                                />
                            }
                        >
                            Eyes
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
